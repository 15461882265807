import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehiclesRoutingModule } from './vehicles-routing.module';

import * as fromComponents from './components';
import { MaintenanceScheduleFormComponent } from './components';
import { FormUtilsModule } from '../core/form-utils/form-utils.module';
import { ColorComponent } from '@fc-shared/ui/color/color.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { HeaderPanelModule } from '../core/header-panel/header-panel.module';
import { ImageLoaderModule } from '../shared/ui/image-loader/image-loader.module';
import { FileCardModule } from '../shared/ui/file-card/file-card.module';
import { VehicleAlertIconsModule } from '../shared/ui/vehicle-alert-icons/vehicle-alert-icons.module';
import { VehicleListItemModule } from '../shared/ui/vehicle-list-item/vehicle-list-item.module';
import { CoreVehicleListContainerModule } from '../shared/ui/core-vehicle-list-container/core-vehicle-list-container.module';
import { MaterialsRangePickerModule } from '../shared/ui/material-range-picker/material-range-picker.module';
import { CustomInlineDatePickerModule } from '@fc-shared/ui/vehicle-date-picker/custom-inline-date-picker.module';
import { MapModule } from '../map/map.module';
import { SortByModule } from '../shared/ui/sort-by/sort-by.module';
import { SearchDropdownModule } from '../shared/ui/search-dropdown/search-dropdown.module';
import { EmptyStateModule } from '../shared/ui/empty-state/empty-state.module';
import { GuideModule } from '../core/guide/guide.module';
import { PhotoUploadCardModule } from '../shared/ui/photo-upload/photo-upload-card/photo-upload-card.module';
import { UploadModule } from '../shared/ui/photo-upload/upload.module';
import { UploadedListModule } from '../shared/ui/photo-upload/uploaded-list/uploaded-list.module';
import { VehicleDriverComponent } from '../shared/ui/vehicle-driver/vehicle-driver.component';
import { RealTimeVideoPopupModule } from '../map/components/real-time-video-popup/real-time-video-popup.module';
import { VehicleGroupColorsModule } from './components/vehicle-group-colors/vehicle-group-colors.module';
import { DvrSettingsModule } from '../settings/components/dvr-settings/dvr-settings.module';
import { SkeletonModule } from '../shared/ui/skeleton/skeleton.module';
import { CameraStatusModule } from '../shared/ui/camera-status/camera-status.module';
import { ConfirmDialogModule } from '../shared/ui/confirm-dialog/confirm-dialog.module';
import { CalendarModule } from '../shared/ui/calendar/calendar.module';
import { ThumbnailsPipeModule } from '../shared/pipes/thumbnails-pipe/thumbnails-pipe.module';
import { VehiclesFilterComponent } from '../shared/ui/vehicle-filter/vehicles-filter.component';
import { EngineLightModule } from '../shared/ui/engine-light/engine-light.module';
import { MaintenanceChartModule } from './components/vehicle-maintenance/maintenance-chart/maintenance-chart.module';
import { MomentFormatPipeModule } from '../shared/pipes/moment-format-pipe/moment-format-pipe.module';
import { GroupsItemModule } from '../shared/ui/groups-item/groups-item.module';
import { ThumbsUrlPipe } from '../shared/pipes/thumbnails-pipe/thumbnails.pipe';
import { MapComponentsModule } from '../shared/ui/map-components/map-components.module';
import { VideoSourceSwitcherModule } from './components/vehicle-dvr/video-source-switcher/video-source-switcher.module';
import { CameraPlaceholderModule } from '../shared/ui/camrea-placeholder/camera-placeholder.module';
import { PageContainerComponent } from '../shared/ui/page-container/page-container.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableCardModule } from '@fc-shared/ui/table-card/table-card.module';
import { AvatarPlaceholderModule } from '@fc-shared/ui/avatar-placeholder/avatar-placeholder.module';
import { IsEmptyPipeModule } from '@fc-shared/pipes/is-empty-pipe/is-empty-pipe.module';
import { TableSearchHeaderModule } from '@fc-shared/ui/tables/table-search-header/table-search-header.module';
import { CoreVehicleFormModule } from './components/core-vehicle-form/core-vehicle-form.module';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderButtonModule } from '@fc-shared/ui/loader-button/loader-button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { HasFeatureModule } from '@fc-shared/directives/has-feature/has-feature.module';
import { HasPermissionDirective } from '@fc-shared/directives/has-permission.directive';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { StatusTagComponent } from '@fc-shared/ui/status-tag/status-tag.component';
import { FilterSelectComponent } from '@fc-shared/ui/core-vehicle-list-container/vehicle-types-filter/filter-select.component';
import { TableContainerHeaderComponent } from '@fc-shared/ui/tables/table-container-header.component';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MediaViewDialogModule } from '@fc-shared/ui/media-view-dialog/media-view-dialog.module';

import * as fromContainers from './containers';
import { VehicleTableModule } from '@fc-shared/ui/tables/vehicle-table/vehicle-table.module';
import { StoreModule } from '@ngrx/store';
import * as fromVehicles from './store';
import { EffectsModule } from '@ngrx/effects';
import { AccordionBaseComponent } from '@fc-vehicles/components/vehicle-detail/item-detail/accordion-base/accordion-base.component';
import { FilledButtonComponent } from '@fc-shared/ui/buttons/filled-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { RecordingsTimelineComponent } from '@fc-vehicles/components/recordings-timeline/recordings-timeline.component';
import { TonalButtonComponent } from '@fc-shared/ui/buttons/tonal-button.component';
import { ClipsListFilterPipe } from '@fc-vehicles/components/vehicle-dvr/clips-list-filter.pipe';
import { ToggleButtonModule } from '@fc-shared/ui/toggle-button/toggle-button.module';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { TagComponent } from '@fc-shared/ui/tags/tag.component';
import { TextButtonComponent } from '@fc-shared/ui/buttons/text-button.component';
import { StrokedButtonComponent } from '@fc-shared/ui/buttons/stroked-button.component';
import { DropdownModule } from '@fc-shared/ui/dropdowns/dropdown.module';
import { ImageViewComponent } from '@fc-shared/ui/image-loader/image-view.component';
import { ErrorComponent } from '@fc-shared/ui/input/core/error/error.component';
import { InputDirective } from '@fc-shared/ui/input/core/input.directive';
import { LabelComponent } from '@fc-shared/ui/input/core/label/label.component';
import { FormFieldComponent } from '@fc-shared/ui/input/core/form-field/form-field.component';

const MATERIAL_MODULES = [
  MatMenuModule,
  MatIconModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatTableModule,
  MatSortModule,
  MatDialogModule,
  MatFormFieldModule,
  MatButtonModule,
  MatPaginatorModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatButtonToggleModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatButtonToggleModule,
  MatButtonToggleModule,
  MatRadioModule,
  MatAutocompleteModule,
  CdkConnectedOverlay,
  CdkOverlayOrigin,
];

@NgModule({
  declarations: [
    ...fromContainers.containers,
    ...fromComponents.components,
    ...fromComponents.dialogs,
  ],
  imports: [
    CalendarModule,
    VehicleDriverComponent,
    CoreVehicleListContainerModule,
    FileCardModule,
    ImageLoaderModule,
    HeaderPanelModule,
    CommonModule,
    VehiclesRoutingModule,
    FormUtilsModule,
    ColorComponent,
    VehicleAlertIconsModule,
    VehicleListItemModule,
    MaterialsRangePickerModule,
    CustomInlineDatePickerModule,
    MapModule,
    SearchDropdownModule,
    SortByModule,
    EmptyStateModule,
    GuideModule,
    PhotoUploadCardModule,
    UploadModule,
    UploadedListModule,
    RealTimeVideoPopupModule,
    VehicleGroupColorsModule,
    DvrSettingsModule,
    SkeletonModule,
    ConfirmDialogModule,
    CameraStatusModule,
    ThumbnailsPipeModule,
    VehiclesFilterComponent,
    EngineLightModule,
    MaintenanceChartModule,
    MomentFormatPipeModule,
    GroupsItemModule,
    MapComponentsModule,
    VideoSourceSwitcherModule,
    CameraPlaceholderModule,
    PageContainerComponent,
    TableCardModule,
    AvatarPlaceholderModule,
    IsEmptyPipeModule,
    CoreVehicleFormModule,
    TableSearchHeaderModule,
    HasFeatureModule,
    LoaderButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HasPermissionDirective,
    StatusTagComponent,
    CdkAccordionModule,
    VehicleTableModule,
    FilterSelectComponent,
    TableContainerHeaderComponent,
    ...MATERIAL_MODULES,
    MediaViewDialogModule,
    StoreModule.forFeature(
      fromVehicles.vehiclesFeatureKey,
      fromVehicles.reducers,
    ),
    EffectsModule.forFeature(fromVehicles.vehiclesEffects),
    FilledButtonComponent,
    IconComponent,
    RecordingsTimelineComponent,
    TonalButtonComponent,
    ClipsListFilterPipe,
    ToggleButtonModule,
    IconButtonComponent,
    TagComponent,
    TextButtonComponent,
    StrokedButtonComponent,
    DropdownModule,
    ImageViewComponent,
    ErrorComponent,
    InputDirective,
    LabelComponent,
    FormFieldComponent,
  ],
  providers: [ThumbsUrlPipe],
  exports: [MaintenanceScheduleFormComponent, AccordionBaseComponent],
})
export class VehiclesModule {}
